import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import { castBody, h2Linked, h2Unlinked, nl2br, diaryArticle } from "./core/core";

import SideNav from "./components/sideNav";
import { hvnUriRoot, apiRoot, rootUri, marinApiRoot } from "./core/defines";
import { useCastData } from "./service/castData";


function castSchedule(d){
    
    return(
        <li className="day"><time>{d.date}</time>
        {d.is_work_day
            ? <>{d.start}~{d.end}</>
            : "-"
        }
        </li>
    )
}

function profContents(profiles){
    const resMaker = (key) => {
        return (<div className="block">
                    <h3>{ profiles[key].title }</h3>
                    <p>{ nl2br(profiles[key].body) }</p>
                </div>)
    }

    if(profiles != undefined){
        return Object.keys(profiles).map((k)=>resMaker(k))
    }

}


function reserveBtn(hvnId){

    let id =""
    let linkTxt= "キャストを予約する"
    if(hvnId){
        id= `?girl_id=${hvnId}`
    }

    return(
        <nav className="wrapper-link">
            <a className="btn-link reserve" 
                href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
        </nav>
    )
}


function linkBtn($url, $txt){
    if($url){
        return(
            <div className="wrapper-link">
                <a className="btn-link link-mov"
                    href={$url}>{$txt}</a>
            </div>
        )
    }    
}

function diaryBlock(diaryData){

    const  diaryArticle = (json)=>{

            //let imgPath = "/img/nophoto-diary.jpg"
            //if(json.url_img != "null"){ imgPath = json.url_img}

            return (
                <li key={json.url_img} className="content">
                    <a href={json.url_link} target="_blank" rel="noreferrer noopner">
                        <div className="wrappar-img">
                            {json.media_type == "video" && <video src={json.content_url}></video>}
                            {json.media_type == "img" && <img src={json.content_url} />}
                        </div>
                        <h3>{json.title}</h3>
                    </a>
                </li>)}

    if(diaryData){

        return(
            <section className="block-diary">
                {h2Unlinked("写メ日記")}
                    <ul className="diary-wrappar">
                        { diaryData.map((d)=> diaryArticle(d)) }
                    </ul>
            </section>
        )
    } 
}

function Profile(){
    const {castId} = useParams();
    const [castData, setCData]    = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [slideArr, setSlideArr] = useState([]);
    const {castDiaryData, getCastDiary, 
           hvnId, updateHvnId, resetHvnId, resetCastData} = useCastData()
    
    
    useEffect(()=>{

        const fetchCastData = (id)=>{
            const URI = marinApiRoot + "/v2/profile?s=heaven_himeji&c=" + id;
            axios({
                method: "get",
                url: URI
                })
                .then((res) => {
                    // console.log("success");
                    // console.log(res.data);
                    // setSchedule(data=res.data);
                    console.log(res.data); 
                    setCData(res.data);
                    setSchedule(res.data.schedule);
                    setSlideArr(res.data.photos);
                    if(res.data.url_diary){
                        updateHvnId(res.data.c_id_hvn)
                        getCastDiary(res.data.url_diary)
                    }    
                })
                .catch(err =>{
                    console.log("err", err);
                });
        }
        fetchCastData(castId);

        return()=>{
            resetHvnId()
            resetCastData()
        }
    },[]);

    //console.log(hvnDiary)

    return(
        <>
        <div className="container bg-w-alpha">
            <article id="profile" className="main-colmn">
                <section>
                    {h2Linked(castData.cast_name, "一覧", "/allcasts")}
                    <div className="txt-center">
                    <Swiper
                        modules={[Autoplay]}
                        loop={true}
                        slidesPerView={1.2}
                        autoplay={true}
                        centeredSlides={true}
                        breakpoints={{ 768:{slidesPerView:3},}}
                    >
                        
                        {Array(9).fill(0).map((_, idx) => {
                            if(slideArr[idx] == 1){
                                return <SwiperSlide key={idx}><img src={ `${rootUri}/d/p${castData.id}_${1+idx}.jpg`} /></SwiperSlide>
                            }
                        })}
                    </Swiper>
                        <h3>{`${castData.cast_name} (${castData.age})`}</h3>
                        {castBody(castData.body)}
                    </div>
                </section>
                <section>
                    {h2Unlinked("プロフィール")}
                    <div className="layout-box">
                        { profContents(castData.profiles) }
                    </div>
                    {h2Unlinked("スケジュール")}
                    <div className="layout-box">
                        <ul className="prof-schedule">
                            {schedule.map((sc)=> castSchedule(sc))}
                        </ul>
                    </div>
                    {castData && reserveBtn(hvnId)} 
                </section>
                {castDiaryData.length 
                    ? diaryBlock(castDiaryData)
                    : null}
                
                {castData && linkBtn(castData.url_diary, "写メ日記をみる")}
                {castData && linkBtn(castData.url_mov, "動画をみる")}
                {castData&&castData.has_gravures &&
                    <ul className="link-btn-last">
                        {castData.url_gravures.map((url, idx)=>
                            <li key={idx}><a href={url}>{castData && linkBtn(castData.url_gravures, "グラビア")}</a></li>
                        )}
                    </ul>
                }

            </article>
            <SideNav />
        </div>
        </>
    )
}
export default Profile;
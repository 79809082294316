import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import SideNav from "./components/sideNav";
import {h2Unlinked, castCard, calender, makeCalenderLink} from "./core/core";
import { apiRoot, marinApiRoot } from "./core/defines"


function getSeletcedW(param){

    let selectedW = new Date().getDay()
    if(param !== undefined){ selectedW = param.split("_")[1] }
    return selectedW
}

function Schedule(){
    const {dateParam} = useParams();
    // console.log(dateParam)
    const [casts,   setSchedule] = useState([]);

    useEffect(()=>{
        const fetchAllcast = (param) =>{
            // 未入力なら当日値取得(デフォ値)
            const target=param != undefined
                    ? `${marinApiRoot}/v2/schedule?s=heaven_himeji&d=${param}`
                    : `${marinApiRoot}/v2/schedule?s=heaven_himeji`

            axios({
                method: "get",
                url: target
            })
            .then((res) => {
                setSchedule(res.data);
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        fetchAllcast(dateParam);
    },[]);

    return(
        <div className="container bg-w-alpha">
            <article className="main-colmn">
                {h2Unlinked("出勤スケジュール")}
                <nav className="block-calender" >{ makeCalenderLink(calender(), getSeletcedW(dateParam)) }</nav>
                    <ul className="wrapper-c-lists">
                        {casts.map((c)=>
                            <li key={c.c_id} className="card-cast">
                                {castCard(c)}
                            </li>
                        )}
                    </ul>
            </article>
            <SideNav />
        </div>
    );
}

export default Schedule;